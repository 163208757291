.wrapper {
    background-image: url('../../assets/images/signin-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100vh; 
}

@media screen and (max-width: 770px) {
    .wrapper{
        background-image: url('../../assets/images/signin-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
    }
}