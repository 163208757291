.hero-wrapper {
    background-image: url('../../assets/images/topBg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 32rem;
    border-bottom-right-radius: 9rem;
}

.userImage {
    background-image: url('../../assets/images/user.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 30rem;
    min-height: 30rem;
    /* position: absolute;
    width: 100%;
    right: -1rem; */
}

.fture-bg {
    background-image: url('../../assets/images/slant-agle.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    width: 100%;
    min-height: 30rem;
}

.curved-bg {
    background-image: url('../../assets/images/curved-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    /* width: 100%; */
    min-height: 30rem;
    max-height: max-content;
}

.phone-bg {
    background-image: url('../../assets/images/phone-hand.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
}

/* .main-content {
    display: block;
} */

.dropdownmenu {
    display: none;
}

@media screen and (max-width: 642px) {
    .main-content {
        display: none;
    }
    
    .dropdownmenu {
        display: block;
    }
    
}

@media screen and (max-width: 1000px) {
    .userImage {
        background-image: url('../../assets/images/user.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom;
        width: 40rem;
        min-height: 27rem;
        position: absolute;
        width: 65%;
        right: -5rem;
        top: -.8rem;
    }
    
}