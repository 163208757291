.large-text {
    font-family: Poppins;
    font-weight: bold !important;
    font-size: 206px;
    line-height: 247px;
    color: #6A6A6A
}
.md-text {
    font-family: Poppins;
    font-weight: bold !important;
    font-size: 40px;
    line-height: 48px;
    color: #BFBFBF
}    
